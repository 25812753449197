*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: Open Sans, Helvetica, sans-serif;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

html {
  --tw-text-opacity: 1;
  color: rgb(21 21 21 / var(--tw-text-opacity));
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Open Sans, Helvetica, sans-serif;
  font-weight: 400;
}

blockquote {
  font-size: 1.125rem;
  line-height: 1.75rem;
  position: relative;
}

@media (min-width: 640px) {
  blockquote {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

blockquote:before {
  content: "";
  z-index: -10;
  height: 4rem;
  width: 4rem;
  background: url("open-quote.737d7c8d.svg") center no-repeat;
  position: absolute;
  top: -1.5rem;
  left: 0;
}

code:before, code:after {
  content: "`";
}

pre {
  --tw-bg-opacity: 1;
  background-color: rgb(21 21 21 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  border-radius: .75rem;
  padding-left: 2rem;
  padding-right: 2rem;
  overflow-x: scroll;
}

pre code:before, pre code:after {
  content: "";
}

table {
  width: 100%;
}

table tr {
  border-top-width: 1px;
}

table th, table td {
  text-align: left;
  vertical-align: top;
  padding-top: .75rem;
  padding-bottom: .75rem;
  padding-right: .75rem;
}

figure {
  max-width: -moz-fit-content;
  max-width: fit-content;
}

figcaption {
  opacity: .6;
  margin-top: .75rem;
  font-style: italic;
  font-weight: 700;
}

img ~ figcaption {
  margin-top: -.75rem;
}

:is(.richtext h1, .richtext h2, .richtext h3, .richtext h4, .richtext h5, .richtext h6, .richtext p, .richtext ul, .richtext ol, .richtext a, .richtext blockquote, .richtext code, .richtext pre, .richtext table, .richtext figure, .richtext iframe, .richtext img, .richtext hr):not(:last-child) {
  margin-bottom: 1.5rem;
}

:is(.richtext p, .richtext ul, .richtext ol, .richtext a, .richtext blockquote, .richtext code, .richtext pre, .richtext table, .richtext figure, .richtext iframe, .richtext img, .richtext hr) + h1, :is(.richtext p, .richtext ul, .richtext ol, .richtext a, .richtext blockquote, .richtext code, .richtext pre, .richtext table, .richtext figure, .richtext iframe, .richtext img, .richtext hr) + h2, :is(.richtext p, .richtext ul, .richtext ol, .richtext a, .richtext blockquote, .richtext code, .richtext pre, .richtext table, .richtext figure, .richtext iframe, .richtext img, .richtext hr) + h3, :is(.richtext p, .richtext ul, .richtext ol, .richtext a, .richtext blockquote, .richtext code, .richtext pre, .richtext table, .richtext figure, .richtext iframe, .richtext img, .richtext hr) + h4, :is(.richtext p, .richtext ul, .richtext ol, .richtext a, .richtext blockquote, .richtext code, .richtext pre, .richtext table, .richtext figure, .richtext iframe, .richtext img, .richtext hr) + h5, :is(.richtext p, .richtext ul, .richtext ol, .richtext a, .richtext blockquote, .richtext code, .richtext pre, .richtext table, .richtext figure, .richtext iframe, .richtext img, .richtext hr) + h6 {
  margin-top: 3rem;
}

.richtext h2 a, .richtext h3 a, .richtext h4 a, .richtext p a, .richtext li a {
  overflow-wrap: break-word;
  text-underline-offset: 4px;
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
  text-decoration-thickness: 1px;
  transition-property: color, background-color, border-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.richtext h2 a:hover, .richtext h3 a:hover, .richtext h4 a:hover, .richtext p a:hover, .richtext li a:hover {
  --tw-text-opacity: 1;
  color: rgb(164 116 0 / var(--tw-text-opacity));
}

.richtext ul, .richtext ol {
  padding-left: 1.25rem;
}

.richtext ul > li {
  margin-top: .75rem;
  list-style-type: disc;
}

.richtext ol > li {
  margin-top: .75rem;
  list-style-type: decimal;
}

.preload {
  opacity: 0;
}

.preload .transition {
  transition-property: none !important;
}

.lazyload, .lazyloading {
  opacity: 0;
}

img.lazyload:not([src]) {
  visibility: hidden;
}

*, :before, :after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.container {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (min-width: 640px) {
  .container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

.button {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(164 116 0 / var(--tw-border-opacity));
  padding: .5rem 1rem;
  transition-property: color, background-color, border-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: table;
}

.button:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(164 116 0 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.inset-0 {
  inset: 0;
}

.bottom-8 {
  bottom: 2rem;
}

.left-0 {
  left: 0;
}

.left-2\/4 {
  left: 50%;
}

.left-8 {
  left: 2rem;
}

.top-0 {
  top: 0;
}

.top-2\/4 {
  top: 50%;
}

.z-10 {
  z-index: 10;
}

.z-50 {
  z-index: 50;
}

.col-span-12 {
  grid-column: span 12 / span 12;
}

.m-auto {
  margin: auto;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.mx-3 {
  margin-left: .75rem;
  margin-right: .75rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-12 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.my-24 {
  margin-top: 6rem;
  margin-bottom: 6rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.-mb-4 {
  margin-bottom: -1rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-24 {
  margin-bottom: 6rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-6 {
  margin-left: 1.5rem;
}

.mr-12 {
  margin-right: 3rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.aspect-square {
  aspect-ratio: 1 / 1;
}

.aspect-ultrawide {
  aspect-ratio: 2.35 / 1;
}

.aspect-video {
  aspect-ratio: 16 / 9;
}

.h-16 {
  height: 4rem;
}

.h-20 {
  height: 5rem;
}

.h-24 {
  height: 6rem;
}

.h-28 {
  height: 7rem;
}

.h-6 {
  height: 1.5rem;
}

.h-full {
  height: 100%;
}

.h-px {
  height: 1px;
}

.min-h-screen {
  min-height: 100vh;
}

.w-12 {
  width: 3rem;
}

.w-16 {
  width: 4rem;
}

.w-4 {
  width: 1rem;
}

.w-5 {
  width: 1.25rem;
}

.w-6 {
  width: 1.5rem;
}

.w-full {
  width: 100%;
}

.max-w-2xl {
  max-width: 42rem;
}

.max-w-3xl {
  max-width: 48rem;
}

.max-w-4xl {
  max-width: 56rem;
}

.max-w-md {
  max-width: 28rem;
}

.max-w-sm {
  max-width: 24rem;
}

.max-w-xl {
  max-width: 36rem;
}

.flex-grow {
  flex-grow: 1;
}

.-translate-x-2\/4 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-2\/4 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-full {
  --tw-translate-y: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-rotate-45 {
  --tw-rotate: -45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.cursor-pointer {
  cursor: pointer;
}

.resize {
  resize: both;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-y-12 {
  row-gap: 3rem;
}

.gap-y-6 {
  row-gap: 1.5rem;
}

.rounded {
  border-radius: .25rem;
}

.rounded-md {
  border-radius: .375rem;
}

.border {
  border-width: 1px;
}

.border-y-\[6px\] {
  border-top-width: 6px;
  border-bottom-width: 6px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-l-\[6px\] {
  border-left-width: 6px;
}

.border-t {
  border-top-width: 1px;
}

.border-gold {
  --tw-border-opacity: 1;
  border-color: rgb(164 116 0 / var(--tw-border-opacity));
}

.border-grey {
  --tw-border-opacity: 1;
  border-color: rgb(204 204 204 / var(--tw-border-opacity));
}

.border-y-transparent {
  border-top-color: #0000;
  border-bottom-color: #0000;
}

.border-l-bc-bronze {
  --tw-border-opacity: 1;
  border-left-color: rgb(166 84 55 / var(--tw-border-opacity));
}

.bg-\[gold\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 215 0 / var(--tw-bg-opacity));
}

.bg-bc-bronze {
  --tw-bg-opacity: 1;
  background-color: rgb(166 84 55 / var(--tw-bg-opacity));
}

.bg-bc-bronze\/50 {
  background-color: #a6543780;
}

.bg-bc-gold {
  --tw-bg-opacity: 1;
  background-color: rgb(226 190 145 / var(--tw-bg-opacity));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(21 21 21 / var(--tw-bg-opacity));
}

.bg-gold {
  --tw-bg-opacity: 1;
  background-color: rgb(164 116 0 / var(--tw-bg-opacity));
}

.bg-green {
  --tw-bg-opacity: 1;
  background-color: rgb(19 50 43 / var(--tw-bg-opacity));
}

.bg-km-black {
  --tw-bg-opacity: 1;
  background-color: rgb(71 78 77 / var(--tw-bg-opacity));
}

.bg-km-peach {
  --tw-bg-opacity: 1;
  background-color: rgb(217 158 137 / var(--tw-bg-opacity));
}

.bg-paleGreen {
  --tw-bg-opacity: 1;
  background-color: rgb(226 230 229 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.fill-current {
  fill: currentColor;
}

.fill-km-black {
  fill: #474e4d;
}

.object-cover {
  object-fit: cover;
}

.p-2 {
  padding: .5rem;
}

.p-4 {
  padding: 1rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.pt-32 {
  padding-top: 8rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.font-arno {
  font-family: arno-pro-caption, serif;
}

.font-sans {
  font-family: Open Sans, Helvetica, sans-serif;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.font-bold {
  font-weight: 700;
}

.font-normal {
  font-weight: 400;
}

.uppercase {
  text-transform: uppercase;
}

.italic {
  font-style: italic;
}

.text-bc-black {
  --tw-text-opacity: 1;
  color: rgb(71 78 77 / var(--tw-text-opacity));
}

.text-bc-bronze {
  --tw-text-opacity: 1;
  color: rgb(166 84 55 / var(--tw-text-opacity));
}

.text-bc-gold {
  --tw-text-opacity: 1;
  color: rgb(226 190 145 / var(--tw-text-opacity));
}

.text-blue-300 {
  --tw-text-opacity: 1;
  color: rgb(147 197 253 / var(--tw-text-opacity));
}

.text-km-black {
  --tw-text-opacity: 1;
  color: rgb(71 78 77 / var(--tw-text-opacity));
}

.text-km-peach {
  --tw-text-opacity: 1;
  color: rgb(217 158 137 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.underline {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

.opacity-30 {
  opacity: .3;
}

.transition {
  transition-property: color, background-color, border-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-transform {
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.underline {
  text-underline-offset: 4px;
  text-decoration-thickness: 1px;
}

.hover\:text-bc-bronze:hover {
  --tw-text-opacity: 1;
  color: rgb(166 84 55 / var(--tw-text-opacity));
}

.hover\:text-gold:hover {
  --tw-text-opacity: 1;
  color: rgb(164 116 0 / var(--tw-text-opacity));
}

.hover\:text-km-peach:hover {
  --tw-text-opacity: 1;
  color: rgb(217 158 137 / var(--tw-text-opacity));
}

.hover\:underline:hover {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

.hover\:no-underline:hover {
  -webkit-text-decoration-line: none;
  text-decoration-line: none;
}

.hover\:opacity-100:hover {
  opacity: 1;
}

.hover\:underline:hover {
  text-underline-offset: 4px;
  text-decoration-thickness: 1px;
}

.focus\:translate-y-0:focus {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group:hover .group-hover\:fill-km-peach {
  fill: #d99e89;
}

.group:hover .group-hover\:no-underline {
  -webkit-text-decoration-line: none;
  text-decoration-line: none;
}

@media (min-width: 640px) {
  .sm\:ml-8 {
    margin-left: 2rem;
  }

  .sm\:mt-12 {
    margin-top: 3rem;
  }

  .sm\:block {
    display: block;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:hidden {
    display: none;
  }

  .sm\:aspect-ultrawide {
    aspect-ratio: 2.35 / 1;
  }

  .sm\:h-24 {
    height: 6rem;
  }

  .sm\:h-32 {
    height: 8rem;
  }

  .sm\:h-8 {
    height: 2rem;
  }

  .sm\:h-auto {
    height: auto;
  }

  .sm\:columns-2 {
    columns: 2;
  }

  .sm\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .sm\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .sm\:gap-x-12 {
    column-gap: 3rem;
  }

  .sm\:gap-x-24 {
    column-gap: 6rem;
  }

  .sm\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .sm\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .sm\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
}

@media (min-width: 768px) {
  .md\:order-2 {
    order: 2;
  }

  .md\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .md\:col-span-5 {
    grid-column: span 5 / span 5;
  }

  .md\:col-span-6 {
    grid-column: span 6 / span 6;
  }

  .md\:col-span-7 {
    grid-column: span 7 / span 7;
  }

  .md\:col-start-6 {
    grid-column-start: 6;
  }

  .md\:m-0 {
    margin: 0;
  }

  .md\:mt-0 {
    margin-top: 0;
  }

  .md\:flex {
    display: flex;
  }

  .md\:h-32 {
    height: 8rem;
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:gap-x-12 {
    column-gap: 3rem;
  }

  .md\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .md\:text-right {
    text-align: right;
  }
}

@media (min-width: 1024px) {
  .lg\:static {
    position: static;
  }

  .lg\:ml-12 {
    margin-left: 3rem;
  }

  .lg\:mt-0 {
    margin-top: 0;
  }

  .lg\:block {
    display: block;
  }

  .lg\:inline-block {
    display: inline-block;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:pt-0 {
    padding-top: 0;
  }

  .lg\:text-left {
    text-align: left;
  }
}

/*# sourceMappingURL=main.css.map */
